exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-project-abacus-tsx": () => import("./../../../src/pages/project/abacus.tsx" /* webpackChunkName: "component---src-pages-project-abacus-tsx" */),
  "component---src-pages-project-baqalat-tsx": () => import("./../../../src/pages/project/baqalat.tsx" /* webpackChunkName: "component---src-pages-project-baqalat-tsx" */),
  "component---src-pages-project-lottie-tsx": () => import("./../../../src/pages/project/lottie.tsx" /* webpackChunkName: "component---src-pages-project-lottie-tsx" */),
  "component---src-pages-project-optimists-tsx": () => import("./../../../src/pages/project/optimists.tsx" /* webpackChunkName: "component---src-pages-project-optimists-tsx" */)
}

